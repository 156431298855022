import React from 'react'
import { Heading, Button, Box } from 'grommet'
import { LinkPrevious } from 'grommet-icons'
import Base from 'layout/Base'
import Container from 'components/Container'

const NotFoundPage = () => (
  <Base>
    <Container>
      <Box align="center" pad="large" margin="large" border="top">
        <Heading size="xlarge" margin="small">
          404
        </Heading>
        <Heading level="2">Page Not Found.</Heading>
        <Box pad="large">
          <Button href="/" icon={<LinkPrevious />} label="Back Home" hoverIndicator plain />
        </Box>
      </Box>
    </Container>
  </Base>
)

export default NotFoundPage
